import jwt from 'jsonwebtoken'

import { jwtConfig, constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { ACCESS_TOKEN, REFRESH_TOKEN },
  ENUM_DATA: {
    USER_ROLES: {
      ADMIN,
      TENANT_ADMIN,
      MANAGER,
      SUPERVISOR,
      ORGANIZATION_VIEWER,
      OFFICE_CLERK,
      GATE_AGENT
    }
  }
} = constants

const setTokens = ({ accessToken, refreshToken }) => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken)
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken)
}

const getTokens = () => {
  return {
    accessToken: window.localStorage.getItem(ACCESS_TOKEN) || '',
    refreshToken: window.localStorage.getItem(REFRESH_TOKEN) || ''
  }
}

const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN) || ''
const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN) || ''

const cleanTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
}

const verifiedAccessToken = (currToken = null) => {
  const accessToken = currToken || getAccessToken()

  if (!accessToken) {
    return null
  }

  try {
    const verified = jwt.verify(
      accessToken.replace('Bearer ', ''),
      jwtConfig.publicKey
    )
    return verified
  } catch (err) {
    return null
  }
}

const isTokenValidOrUndefined = () => {
  const accessToken = getAccessToken()

  if (!accessToken) {
    return true
  }

  try {
    const verified = jwt.verify(
      accessToken.replace('Bearer ', ''),
      jwtConfig.publicKey
    )
    return !!verified
  } catch (error) {
    return false
  }
}

const getRole = user => (user && user.role ? user.role : null)
const isAdmin = role => role === ADMIN
const isTenantAdmin = role => role === TENANT_ADMIN
const isManager = role => role === MANAGER
const isSupervisor = role => role === SUPERVISOR
const isOrganizationViewer = role => role === ORGANIZATION_VIEWER
const isOfficeClerk = role => role === OFFICE_CLERK
const isGateAgent = role => role === GATE_AGENT

const getUserData = () => {
  const { accessToken, refreshToken } = getTokens()
  const user = verifiedAccessToken()

  return {
    accessToken,
    refreshToken,
    user,
    isAdmin: isAdmin(getRole(user)),
    isTenantAdmin: isTenantAdmin(getRole(user)),
    isManager: isManager(getRole(user)),
    isSupervisor: isSupervisor(getRole(user)),
    isOrganizationViewer: isOrganizationViewer(getRole(user)),
    isOfficerClerk: isOfficeClerk(getRole(user)),
    isGateAgent: isGateAgent(getRole(user))
  }
}

export default {
  setTokens,
  getTokens,
  getAccessToken,
  getRefreshToken,
  cleanTokens,
  verifiedAccessToken,
  isTokenValidOrUndefined,
  getUserData,
  isAdmin,
  isTenantAdmin,
  isManager,
  isSupervisor,
  isOrganizationViewer,
  isOfficeClerk,
  isGateAgent
}
