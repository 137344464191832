import React from 'react'
import ReactDOM from 'react-dom'
import { CssBaseline } from '@mui/material'

import { Providers } from 'components'
import { analyticsUtils } from 'utils'

import App from './App'
import * as serviceWorker from './serviceWorker'

import 'config/i18n'
import 'config/moment'

analyticsUtils.init()

ReactDOM.render(
  <Providers>
    <CssBaseline />
    <App />
  </Providers>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
