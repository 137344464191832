import React, { createContext, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

const AppBarContext = createContext()

const initialState = {
  title: null,
  allowGoBack: false,
  printIcon: false,
  to: '/transactions'
}

function AppBarProvider(props) {
  const [data, setData] = useState(initialState)

  const cleanAppBarData = useCallback(() => {
    setData(initialState)
  }, [])

  const setAppBarData = useCallback(newData => {
    newData ? setData({ ...initialState, ...newData }) : setData(initialState)
  }, [])

  return (
    <AppBarContext.Provider
      value={{
        appBarData: data,
        setAppBarData,
        cleanAppBarData
      }}
      {...props}
    />
  )
}

function useAppBar() {
  const context = useContext(AppBarContext)

  if (context === undefined) {
    throw new Error(`useAppBar must be used within a AppBarProvider`)
  }

  return context
}

AppBarProvider.propTypes = {
  props: PropTypes.object
}

export { AppBarProvider, useAppBar }
