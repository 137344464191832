import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { analyticsUtils } from 'utils'

function usePageviewAnalytics() {
  const location = useLocation()

  useEffect(() => {
    const currentPath = location.pathname + location.search
    analyticsUtils.sendPageview(currentPath)
  }, [location])
}

export default usePageviewAnalytics
