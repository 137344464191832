import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'
import PropTypes from 'prop-types'

import { constants } from 'config'
import { templateUtils } from 'utils'

const {
  LOCAL_STORAGE_KEYS: { TRANSACTION, TRANSACTION_TEMPLATE }
} = constants

const TransactionContext = createContext()

function TransactionProvider(props) {
  const [data, setData] = useState(null)
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (data) {
      window.localStorage.setItem(TRANSACTION, JSON.stringify(data))
    }
  }, [data])

  useEffect(() => {
    if (template) {
      window.localStorage.setItem(
        TRANSACTION_TEMPLATE,
        JSON.stringify(template)
      )
    }
  }, [template])

  useEffect(() => {
    const persistTransactionData = window.localStorage.getItem(TRANSACTION)
    const persistTransactionTemplate = window.localStorage.getItem(
      TRANSACTION_TEMPLATE
    )

    if (persistTransactionData && persistTransactionTemplate) {
      setData(JSON.parse(persistTransactionData))
      setTemplate(JSON.parse(persistTransactionTemplate))
    }
  }, [])

  const setTransactionData = useCallback(
    (newData, overrideCurrentData = true) => {
      setData(currentData =>
        overrideCurrentData
          ? { ...currentData, ...newData }
          : { ...newData, ...currentData }
      )
    },
    []
  )

  const setTransactionTemplate = useCallback(template => {
    setTemplate(templateUtils.sortByPosition(template))
  }, [])

  const cleanTransactionData = useCallback(() => {
    window.localStorage.removeItem(TRANSACTION)
    window.localStorage.removeItem(TRANSACTION_TEMPLATE)
    setData(null)
    setTemplate(null)
  }, [])

  return (
    <TransactionContext.Provider
      value={{
        transactionData: data,
        setTransactionData,
        cleanTransactionData,
        setTransactionTemplate,
        transactionTemplate: template
      }}
      {...props}
    />
  )
}

function useTransaction() {
  const context = useContext(TransactionContext)

  if (context === undefined) {
    throw new Error(`useTransaction must be used within a TransactionProvider`)
  }

  return context
}

TransactionProvider.propTypes = {
  props: PropTypes.object
}

export { TransactionProvider, useTransaction }
