const sortByPosition = template =>
  Object.entries(template)
    .sort(([, a], [, b]) => a.position - b.position)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

const getNextStep = (template, currentStep) => {
  if (!currentStep) {
    const nextStep = Object.keys(template)
      .map(step => template[step])
      .find(step => step.position === 0)

    return nextStep && nextStep.path
  }

  const nextStep = Object.keys(template)
    .map(step => template[step])
    .find(step => step.position === currentStep.position + 1)

  return (nextStep && nextStep.path) || 'check'
}

const isPhoto = customField =>
  typeof customField === 'object' && customField?.hash

export default {
  sortByPosition,
  getNextStep,
  isPhoto
}
