import React, { useState, useCallback } from 'react'
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  Print as PrintIcon
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Link, Logo } from 'components'
import { useAuth } from 'context/auth/auth'
import { useTransaction } from 'context/transaction'
import { useAppBar } from 'context/appBar'
import { hapiConfig } from 'config'

function AppBarComponent() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { logout } = useAuth()
  const history = useHistory()
  const { cleanTransactionData } = useTransaction()
  const { appBarData } = useAppBar()
  const {
    t,
    i18n: { language }
  } = useTranslation()
  const theme = useTheme()
  const { to, title, allowGoBack, printIcon, transactionId } = appBarData

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleShowProfile = useCallback(() => {
    history.push('/profile')
    handleClose()
  }, [history, handleClose])

  const handlePrint = useCallback(() => {
    window.open(
      `${hapiConfig.url}report/transaction?id=${transactionId}&lng=${language}`
    )
  }, [transactionId, language])

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <>
      <AppBar>
        <Toolbar>
          <Box
            display='flex'
            flex={1}
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              color='common.white'
            >
              {allowGoBack ? (
                <Tooltip title={t('goBack')}>
                  <IconButton onClick={handleGoBack} size='large'>
                    <Box
                      display='flex'
                      alignItems='center'
                      color='common.white'
                    >
                      <ArrowBackIcon size={theme.mixins.toolbar.minHeight} />
                    </Box>
                  </IconButton>
                </Tooltip>
              ) : (
                <Link to={to} onClick={cleanTransactionData}>
                  <Logo height={theme.mixins.toolbar.minHeight} />
                </Link>
              )}
              {title && (
                <Typography variant='h6' component='div'>
                  <Box ml={1}>{title}</Box>
                </Typography>
              )}
            </Box>
            {printIcon ? (
              <Tooltip title={t('print')}>
                <IconButton onClick={handlePrint} size='large'>
                  <PrintIcon sx={{ color: theme.palette.common.white }} />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <AccountCircleIcon onClick={handleClick} />
                <Menu
                  id='profile-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleShowProfile}>
                    {t('profile')}
                  </MenuItem>
                  <MenuItem onClick={logout}>{t('logout')}</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default AppBarComponent
