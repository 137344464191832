import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'

function BaseDialog({ ...props }) {
  return <Dialog {...props} />
}

BaseDialog.propTypes = {
  props: PropTypes.object
}

export default BaseDialog
