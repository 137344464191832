import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { resources } from '@edenia/smartgate-i18n-module'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', lng => {
    moment.locale(lng)
  })
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
