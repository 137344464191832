import React from 'react'
import { useLocation } from 'react-router-dom'

import Stepper from './Stepper'

function Footer() {
  const { pathname } = useLocation()

  if (pathname.includes('succeed') || pathname.includes('error')) {
    return null
  }

  if (
    (pathname.startsWith('/create') && !pathname.includes('door')) ||
    (pathname.startsWith('/update') && !pathname.includes('door'))
  ) {
    return <Stepper />
  }

  return null
}

export default Footer
