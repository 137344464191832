import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@mui/material'

function BaseMenu({ ...props }) {
  return <Menu {...props} />
}

BaseMenu.propTypes = {
  props: PropTypes.object
}

export default BaseMenu
