import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

function ContainerComponent({ children }) {
  return (
    <Box pb={8} pt={2} px={1} component='main'>
      {children}
    </Box>
  )
}

ContainerComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default ContainerComponent
