import React from 'react'
import PropTypes from 'prop-types'
import {
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import { LocationOn as LocationOnIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { BaseDialog } from 'components'

function LocationSelect({ locations, onLocationSelect }) {
  const { t } = useTranslation()

  return (
    <BaseDialog fullWidth open={!!locations}>
      <DialogTitle>{t('selectLocation')}</DialogTitle>
      <List>
        {locations &&
          locations.map(location => (
            <ListItem
              button
              onClick={() => onLocationSelect(location)}
              key={location.id}
            >
              <ListItemAvatar>
                <Avatar>
                  <LocationOnIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={location.name} />
            </ListItem>
          ))}
      </List>
    </BaseDialog>
  )
}

LocationSelect.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  onLocationSelect: PropTypes.func.isRequired
}

export default LocationSelect
