import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Stepper, Step, StepLabel } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useTransaction } from 'context/transaction'

const StyledStepper = styled(Stepper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  padding: 5,
  width: '100%',
  zIndex: 999,
  backgroundColor: theme.palette.background.paper
}))

const StyledStepLabel = styled(StepLabel)({
  ['& .MuiStepLabel-label']: {
    marginTop: '0px !important',
    height: 20
  }
})

function StepperComponent() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [activeStep, setActiveStep] = useState(0)
  const { transactionTemplate } = useTransaction()

  useEffect(() => {
    const isCreate = pathname.startsWith('/create/')
    const splitPathname = pathname.split('/')
    const path = isCreate
      ? pathname.replace('/create/', '')
      : splitPathname?.[splitPathname?.length - 1]

    const position = transactionTemplate?.[path]?.position

    setActiveStep(
      typeof position === 'number'
        ? position
        : transactionTemplate && Object.keys(transactionTemplate).length + 1
    )
  }, [pathname, transactionTemplate])

  return transactionTemplate ? (
    <StyledStepper activeStep={activeStep} alternativeLabel>
      {Object.keys(transactionTemplate).map(step => {
        const { name, path } = transactionTemplate[step]
        return (
          <Step key={path}>
            <StyledStepLabel>{name}</StyledStepLabel>
          </Step>
        )
      })}

      <Step>
        <StyledStepLabel>{t('check')}</StyledStepLabel>
      </Step>
    </StyledStepper>
  ) : null
}

export default StepperComponent
