import ReactGA from 'react-ga'

import { analyticsConfig } from 'config'

const init = (trackingCode = analyticsConfig.trackingCode) => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ReactGA.initialize(trackingCode, { debug: isDev })
}

const sendEvent = ({ category, action, ...rest }) => {
  ReactGA.event({ category, action, ...rest })
}

const sendPageview = path => {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

export default {
  init,
  sendEvent,
  sendPageview
}
