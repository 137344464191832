import sha512 from 'crypto-js/sha512'

const updateProfileFormat = ({
  id,
  first_name,
  last_name,
  email,
  user_name
}) => {
  return {
    variables: {
      id,
      first_name,
      last_name,
      email,
      user_name
    }
  }
}

const formatLogin = ({ emailOrUsername, password }) => {
  return {
    emailOrUsername,
    password: sha512(password).toString()
  }
}

const formatResetPassword = ({ user_name, password, secret }) => {
  return {
    user_name,
    secret,
    password: sha512(password).toString()
  }
}

const formatChangePassword = ({
  currentPassword,
  newPassword,
  newPasswordConfirmation
}) => {
  return {
    variables: {
      currentPassword: sha512(currentPassword).toString(),
      newPassword: sha512(newPassword).toString(),
      newPasswordConfirmation: sha512(newPasswordConfirmation).toString()
    }
  }
}

export default {
  updateProfileFormat,
  formatLogin,
  formatResetPassword,
  formatChangePassword
}
