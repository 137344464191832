import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@mui/material'

function BaseCard({ ...props }) {
  return <Card square elevation={4} {...props} />
}

BaseCard.propTypes = {
  props: PropTypes.object
}

export default BaseCard
