import React from 'react'
import PropTypes from 'prop-types'

import AppBar from './AppBar'
import Container from './Container'
import Footer from './Footer'

function Layout({ children }) {
  return (
    <>
      <AppBar />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default Layout
