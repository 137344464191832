import { constants } from 'config'

const {
  ENUM_DATA: {
    PHOTO_TYPES: {
      CONTAINER,
      CONTAINER_EMPTY,
      SEAL,
      DRIVER,
      DRIVER_ID,
      PLATE,
      DAMAGE,
      CONTAINER_CONDITION
    }
  }
} = constants

const formatPhotosObject = photos => {
  const response = {}

  photos.map(photo => {
    const basePhoto = {
      type: photo.type,
      hash: photo.hash
    }
    switch (photo.type) {
      case CONTAINER:
        response.container_photo = basePhoto
        break
      case CONTAINER_EMPTY:
        response.container_empty_photo = basePhoto
        break
      case SEAL:
        if (!response.seal_photos) {
          response.seal_photos = []
        }
        response.seal_photos.push({
          ...basePhoto,
          seal_type: photo.seals?.[0]?.type,
          value: photo.seals?.[0]?.value
        })
        break
      case DRIVER:
        response.driver_photo = basePhoto
        break
      case DRIVER_ID:
        response.driver_id_photo = basePhoto
        break
      case PLATE:
        response.plate_photo = basePhoto
        break
      case DAMAGE:
        if (!response.damage_photos) {
          response.damage_photos = []
        }
        response.damage_photos.push({
          ...basePhoto,
          damage_type: photo.damages?.[0]?.type
        })
        break
      case CONTAINER_CONDITION:
        if (!response.container_condition_photos) {
          response.container_condition_photos = []
        }
        response.container_condition_photos.push(basePhoto)
        break
      default:
        break
    }

    return photo
  })

  return response
}

export default {
  formatPhotosObject
}
