import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { constants } from 'config'
import { useTransaction } from 'context/transaction'
import { FileDetail } from 'components'

const {
  FIELD_TYPES: { NUMBER, STRING, BOOLEAN, SELECT, PHOTO, MULTI_SELECT },
  REQUIRED_FIELDS: { DISABLE_CHECK_DIGIT_VALIDATION },
  DEFAULT_FIELDS: {
    CONTAINER_PHOTO,
    CONTAINER_NUMBER,
    TRANSPORTATION_TYPE,
    CONTAINER_STATUS,
    CONTAINER_ISO_TYPE,
    MGW_KILOGRAMS,
    MAX_PAYLOAD_KILOGRAMS,
    TARE_KILOGRAMS,
    CARGO_WEIGHT_KILOGRAMS,
    VOLUME_CBM,
    CONTAINER_YEAR,
    CONTAINER_EMPTY_PHOTO,
    SEAL_PHOTOS,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    BOOKING_NUMBER,
    DRIVER_ID_PHOTO,
    DRIVER_NAME,
    DRIVER_ID,
    DRIVER_PHOTO,
    PLATE_PHOTO,
    PLATE_ID,
    IS_DAMAGE,
    DAMAGE_PHOTOS,
    CONTAINER_CONDITION,
    STACK,
    COMMENTS,
    CONTAINER_CONDITION_PHOTOS,
    PTI_PASS,
    CLEAN,
    SET_POINT_CELSIUS,
    RETURN_TEMPERATURE,
    SUPPLY_TEMPERATURE,
    HUMIDITY_SET_POINT,
    HUMIDITY_ACTUAL,
    VENTILATION
  },
  DOOR_FIELDS: { CLIENT_ID }
} = constants

function PreviewFieldsFactory({ isDefault = true, name, type }) {
  const { transactionData } = useTransaction()
  const { t } = useTranslation()

  const value = transactionData?.[name]

  if (isDefault) {
    switch (name) {
      case CONTAINER_NUMBER:
      case CONTAINER_ISO_TYPE:
      case MGW_KILOGRAMS:
      case MAX_PAYLOAD_KILOGRAMS:
      case TARE_KILOGRAMS:
      case CARGO_WEIGHT_KILOGRAMS:
      case VOLUME_CBM:
      case BOOKING_NUMBER:
      case DRIVER_NAME:
      case DRIVER_ID:
      case PLATE_ID:
      case STACK:
      case COMMENTS:
      case CONTAINER_YEAR:
      case CONTAINER_CONDITION:
      case SET_POINT_CELSIUS:
      case RETURN_TEMPERATURE:
      case SUPPLY_TEMPERATURE:
      case HUMIDITY_SET_POINT:
      case HUMIDITY_ACTUAL:
      case VENTILATION:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${value}`}
          </Typography>
        )

      case TRANSPORTATION_TYPE:
      case CONTAINER_STATUS:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${t(value)}`}
          </Typography>
        )

      case IS_DAMAGE:
      case PTI_PASS:
      case CLEAN:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${t(
              transactionData?.[name]?.toString()?.toUpperCase()
            )}`}
          </Typography>
        )

      case CLIENT_ID:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${transactionData?.client?.name || ''}`}
          </Typography>
        )

      case LINER_ID:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${transactionData?.liner?.name || ''}`}
          </Typography>
        )

      case SHIPPER_ID:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${transactionData?.shipper?.name || ''}`}
          </Typography>
        )

      case CONSIGNEE_ID:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${transactionData?.consignee?.name || ''}`}
          </Typography>
        )

      case CONTAINER_EMPTY_PHOTO:
      case DRIVER_ID_PHOTO:
      case DRIVER_PHOTO:
      case PLATE_PHOTO:
      case CONTAINER_PHOTO:
        return <FileDetail label={t(name)} value={value?.hash} />

      case CONTAINER_CONDITION_PHOTOS:
        return (
          <>
            {value
              ? value.map(({ hash }) => {
                  return <FileDetail key={hash} label={t(name)} value={hash} />
                })
              : null}
          </>
        )

      case SEAL_PHOTOS:
        return (
          <>
            {value
              ? value.map(({ seal_type, value, hash }) => {
                  return (
                    <FileDetail
                      key={hash}
                      label={`${t(seal_type)} - ${value}`}
                      value={hash}
                    />
                  )
                })
              : null}
          </>
        )

      case DAMAGE_PHOTOS:
        return (
          <>
            {value
              ? value.map(({ damage_type, hash }) => {
                  return (
                    <FileDetail
                      key={hash}
                      label={t(damage_type)}
                      value={hash}
                    />
                  )
                })
              : null}
          </>
        )

      case DISABLE_CHECK_DIGIT_VALIDATION:
        return null

      default:
        return (
          <Typography variant='caption' gutterBottom>
            {`${t(name)}: ${
              typeof value === 'object' ? JSON.stringify(value) : value
            }`}
          </Typography>
        )
    }
  }

  switch (type) {
    case NUMBER:
    case STRING:
    case SELECT:
      return (
        <Typography variant='caption' gutterBottom>
          {`${t(name)}: ${value}`}
        </Typography>
      )

    case BOOLEAN:
      return (
        <Typography variant='caption' gutterBottom>
          {`${t(name)}: ${t(value.toString().toUpperCase())}`}
        </Typography>
      )

    case MULTI_SELECT:
      return (
        <Typography variant='caption' gutterBottom>
          {`${t(name)}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`}
        </Typography>
      )

    case PHOTO:
      return <FileDetail label={t(name)} value={value?.hash} />

    default:
      return (
        <Typography variant='caption' gutterBottom>
          {`${t(name)}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`}
        </Typography>
      )
  }
}

PreviewFieldsFactory.propTypes = {
  isDefault: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf([NUMBER, STRING, BOOLEAN, SELECT, MULTI_SELECT, PHOTO])
}

export default PreviewFieldsFactory
