import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

const getArrayOfLastYears = (length = 31) => {
  const years = []
  const currentYear = moment().year()
  for (let index = 0; index < length; index++) {
    years.push(currentYear - index)
  }

  return years
}

export default {
  getArrayOfLastYears
}
