import React from 'react'
import PropTypes from 'prop-types'

import { usePageviewAnalytics } from 'hooks'

function Analytics({ children }) {
  usePageviewAnalytics()

  return <>{children}</>
}

Analytics.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default Analytics
