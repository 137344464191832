import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@mui/material/styles'

import AuthProviders from 'context/auth'
import { themeConfig } from 'config'

function Providers({ children }) {
  return (
    <ThemeProvider theme={themeConfig}>
      <AuthProviders>{children}</AuthProviders>
    </ThemeProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default Providers
