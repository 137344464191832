import React from 'react'
import PropTypes from 'prop-types'

import logo from 'assets/logo.png'

function Logo(props) {
  return <img src={logo} {...props} alt='smargateIcon' />
}

Logo.propTypes = {
  props: PropTypes.object
}

export default Logo
