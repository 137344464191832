import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#2d3db5'
    },
    secondary: {
      main: '#e2882e'
    }
  },
  typography: {
    fontFamily: 'Lato, sans-serif'
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase !important'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    }
  }
})
