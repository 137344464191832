import React from 'react'
import PropTypes from 'prop-types'
import { Box, InputBase } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

function BaseSearchInput({ value, onChange }) {
  const { t } = useTranslation()
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      flexDirection='row'
      my={1}
      position='relative'
      borderBottom={1}
      borderBottomColor='grey.300'
    >
      <SearchIcon sx={{ mr: 1 }} />
      <InputBase
        placeholder={t('searchTiquets')}
        value={value}
        onChange={onChange}
        name='search'
        type='search'
        fullWidth
        autoComplete='off'
      />
    </Box>
  )
}

BaseSearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default BaseSearchInput
