import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { ipfsConfig } from 'config'

const StyledImg = styled('img')({
  maxWidth: '100%',
  maxHeight: 175,
  minHeight: 175,
  borderRadius: 3
})

function FileUpload({ value, spacing = false }) {
  if (!value) {
    return null
  }

  return (
    <Box display='flex' justifyContent='center' mb={spacing ? 2 : 0}>
      <StyledImg src={`${ipfsConfig.url}${value}`} alt={value} />
    </Box>
  )
}

FileUpload.propTypes = {
  value: PropTypes.string,
  spacing: PropTypes.bool
}

export default FileUpload
