import React, {
  useReducer,
  createContext,
  useContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'

import { authUtils, fetchUtils, analyticsUtils } from 'utils'

const AuthContext = createContext()

function AuthProvider(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      temptoken: null,
      data: {
        ...authUtils.getUserData(),
        locations: null
      }
    }
  )

  const setLocation = useCallback(
    async location => {
      try {
        setState({
          loading: true,
          error: null,
          data: {
            ...state.data,
            locations: null
          }
        })

        const { accessToken, refreshToken } = await fetchUtils.post(
          'auth/set-location',
          {
            locationId: location.id,
            token: state.temptoken
          }
        )

        authUtils.setTokens({ accessToken, refreshToken })

        setState({
          loading: false,
          temptoken: null,
          data: {
            ...state.data,
            ...authUtils.getUserData(),
            locations: null
          }
        })
      } catch (error) {
        setState({
          loading: false,
          error
        })
      }
    },
    [state.data, state.temptoken]
  )

  const login = useCallback(
    async ({ emailOrUsername, password }) => {
      try {
        setState({
          loading: true,
          error: null
        })

        const {
          accessToken,
          refreshToken
        } = await fetchUtils.post('auth/login', { emailOrUsername, password })

        const { locations } = authUtils.verifiedAccessToken(accessToken)

        if (locations.length === 1) {
          authUtils.setTokens({ accessToken, refreshToken })

          setState({
            loading: false,
            data: {
              ...state.data,
              ...authUtils.getUserData()
            }
          })
        } else {
          setState({
            loading: false,
            temptoken: accessToken,
            data: {
              ...state.data,
              locations
            }
          })
        }

        analyticsUtils.sendEvent({
          category: 'Auth',
          action: 'Login successful',
          label: `The user ${emailOrUsername} just logged in`
        })
      } catch (error) {
        setState({
          loading: false,
          error
        })

        analyticsUtils.sendEvent({
          category: 'Auth',
          action: 'Login failed',
          label: `The user ${emailOrUsername} just failed to login`
        })
      }
    },
    [state.data]
  )

  const logout = useCallback(() => {
    authUtils.cleanTokens()
    setState({
      data: {
        ...state.data,
        ...authUtils.getUserData()
      }
    })
    analyticsUtils.sendEvent({ category: 'Auth', action: 'Logout' })
  }, [state.data])

  return (
    <AuthContext.Provider
      value={{
        login,
        setLocation,
        logout,
        ...state
      }}
      {...props}
    />
  )
}

function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

AuthProvider.propTypes = {
  props: PropTypes.object
}

export { AuthProvider, useAuth }
