import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

function BaseButton({ color = 'secondary', spacing = false, ...props }) {
  return (
    <Button
      fullWidth
      variant='contained'
      color={color}
      sx={{ mb: spacing ? 2 : 0 }}
      {...props}
    />
  )
}

BaseButton.propTypes = {
  color: PropTypes.string,
  props: PropTypes.object,
  spacing: PropTypes.bool
}

export default BaseButton
