import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

const constants = {
  DEFAULT_TIMEZONE: moment.tz.guess(),
  MAX_IMAGE_QUALITY_HIGH: 0.35,
  MAX_IMAGE_QUALITY_LOW: 0.2,
  ACCESS_TOKEN_FIELD: 'accessToken',
  LOCAL_STORAGE_KEYS: {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TRANSACTION: 'TRANSACTION',
    I18N: 'i18nextLng',
    TRANSACTION_TEMPLATE: 'TRANSACTION_TEMPLATE'
  },
  ENUM_DATA: {
    DAMAGE_TYPES: {
      DIRTY: 'DIRTY',
      HOLE: 'HOLE',
      FLOOR_DAMAGE: 'FLOOR_DAMAGE',
      SCRATCHED_FLOOR: 'SCRATCHED_FLOOR',
      RUST: 'RUST',
      DOOR_SEAL_DAMAGE: 'DOOR_SEAL_DAMAGE',
      WALL_DENT: 'WALL_DENT',
      CEILING_DENT: 'CEILING_DENT',
      STRUCTURAL_DAMAGE: 'STRUCTURAL_DAMAGE',
      CORNER_POST_DAMAGE: 'CORNER_POST_DAMAGE',
      SIDE_RAIL_DAMAGE: 'SIDE_RAIL_DAMAGE',
      CORNER_CASTING_DAMAGE: 'CORNER_CASTING_DAMAGE',
      DOOR_BARS_HANDLES: 'DOOR_BARS_HANDLES',
      SEAL_BLOCK_DAMAGE: 'SEAL_BLOCK_DAMAGE',
      PAINT: 'PAINT',
      ODOR: 'ODOR',
      CABLE_MISSING: 'CABLE_MISSING',
      PLUG_MISSING: 'PLUG_MISSING',
      REEFER_UNIT_PARTS_MISSING: 'REEFER_UNIT_PARTS_MISSING',
      OIL_PRESENT: 'OIL_PRESENT'
    },
    SEAL_TYPES: {
      CARRIER: 'CARRIER',
      SHIPPER: 'SHIPPER',
      VETERINARY_1: 'VETERINARY_1',
      VETERINARY_2: 'VETERINARY_2',
      CUSTOMS: 'CUSTOMS',
      TERMINAL: 'TERMINAL',
      VENTILATION: 'VENTILATION',
      FUMIGATION: 'FUMIGATION',
      OTHER_1: 'OTHER_1',
      OTHER_2: 'OTHER_2'
    },
    BASE_TRANSACTION_TYPES: {
      GATE_IN: 'GATE_IN',
      GATE_OUT: 'GATE_OUT'
    },
    TRANSACTION_TYPES: {
      GATE_IN_EMPTY: 'GATE_IN_EMPTY',
      GATE_IN_LOADED: 'GATE_IN_LOADED',
      GATE_OUT_EMPTY: 'GATE_OUT_EMPTY',
      GATE_OUT_LOADED: 'GATE_OUT_LOADED'
    },
    TRANSACTION_TRANSPORTATION_TYPES: {
      EXPORT: 'EXPORT',
      IMPORT: 'IMPORT'
    },
    CONTAINER_STATUSES: {
      EMPTY: 'EMPTY',
      LOADED: 'LOADED'
    },
    PHOTO_TYPES: {
      CONTAINER: 'CONTAINER',
      CONTAINER_EMPTY: 'CONTAINER_EMPTY',
      SEAL: 'SEAL',
      DRIVER: 'DRIVER',
      DRIVER_ID: 'DRIVER_ID',
      PLATE: 'PLATE',
      DAMAGE: 'DAMAGE',
      CONTAINER_CONDITION: 'CONTAINER_CONDITION',
      CUSTOM: 'CUSTOM'
    },
    USER_ROLES: {
      ADMIN: 'admin',
      TENANT_ADMIN: 'tenantAdmin',
      MANAGER: 'manager',
      SUPERVISOR: 'supervisor',
      ORGANIZATION_VIEWER: 'organizationViewer',
      OFFICE_CLERK: 'officeClerk',
      GATE_AGENT: 'gateAgent'
    }
  },
  PHOTO_KEYS: {
    PHOTO: 'photo',
    CONTAINER_PHOTO: 'container_photo'
  },
  FIELD_TYPES: {
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    SELECT: 'SELECT',
    PHOTO: 'PHOTO',
    MULTI_SELECT: 'MULTI_SELECT'
  },
  REQUIRED_FIELDS: {
    CONTAINER_PHOTO: 'container_photo',
    DISABLE_CHECK_DIGIT_VALIDATION: 'disable_check_digit_validation',
    CONTAINER_NUMBER: 'container_number',
    TRANSPORTATION_TYPE: 'transportation_type',
    CONTAINER_STATUS: 'container_status',
    CLIENT_ID: 'client_id'
  },
  REQUIRED_UPDATE_FIELDS: {
    CONTAINER_PHOTO: 'container_photo',
    CONTAINER_NUMBER: 'container_number',
    CLIENT_ID: 'client_id'
  },
  EXTRA_FIELDS: {
    TYPE: 'type',
    TEMPLATE_ID: 'template_id',
    IS_RELEASE_REQUIRED: 'is_release_required'
  },
  DOOR_FIELDS: {
    ID: 'id',
    UUID: 'uuid',
    CONTAINER_NUMBER: 'container_number',
    TRANSPORTATION_TYPE: 'transportation_type',
    CONTAINER_STATUS: 'container_status',
    CLIENT_ID: 'client_id',
    TYPE: 'type',
    CONSECUTIVE_FOLIO_NUMBER: 'consecutive_folio_number',
    CREATED_BY: 'createdBy',
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at'
  },
  DEFAULT_FIELDS: {
    CONTAINER_PHOTO: 'container_photo',
    CONTAINER_NUMBER: 'container_number',
    TRANSPORTATION_TYPE: 'transportation_type',
    CONTAINER_STATUS: 'container_status',
    CONTAINER_ISO_TYPE: 'container_iso_type',
    MGW_KILOGRAMS: 'mgw_kilograms',
    MAX_PAYLOAD_KILOGRAMS: 'max_payload_kilograms',
    TARE_KILOGRAMS: 'tare_kilograms',
    CARGO_WEIGHT_KILOGRAMS: 'cargo_weight_kilograms',
    VOLUME_CBM: 'volume_cbm',
    CONTAINER_YEAR: 'container_year',
    CONTAINER_EMPTY_PHOTO: 'container_empty_photo',
    SEAL_PHOTOS: 'seal_photos',
    LINER_ID: 'liner_id',
    SHIPPER_ID: 'shipper_id',
    CONSIGNEE_ID: 'consignee_id',
    BOOKING_NUMBER: 'booking_number',
    DRIVER_ID_PHOTO: 'driver_id_photo',
    DRIVER_NAME: 'driver_name',
    DRIVER_ID: 'driver_id',
    DRIVER_PHOTO: 'driver_photo',
    PLATE_PHOTO: 'plate_photo',
    PLATE_ID: 'plate_id',
    IS_DAMAGE: 'is_damage',
    DAMAGE_PHOTOS: 'damage_photos',
    CONTAINER_CONDITION: 'container_condition',
    STACK: 'stack',
    COMMENTS: 'comments',
    CONTAINER_CONDITION_PHOTOS: 'container_condition_photos',
    PTI_PASS: 'pti_pass',
    CLEAN: 'clean',
    SET_POINT_CELSIUS: 'set_point_celsius',
    RETURN_TEMPERATURE: 'return_temperature',
    SUPPLY_TEMPERATURE: 'supply_temperature',
    HUMIDITY_SET_POINT: 'humidity_set_point',
    HUMIDITY_ACTUAL: 'humidity_actual',
    VENTILATION: 'ventilation'
  },
  PHOTO_FIELDS: {
    CONTAINER_PHOTO: 'container_photo',
    CONTAINER_EMPTY_PHOTO: 'container_empty_photo',
    SEAL_PHOTOS: 'seal_photos',
    DRIVER_ID_PHOTO: 'driver_id_photo',
    DRIVER_PHOTO: 'driver_photo',
    PLATE_PHOTO: 'plate_photo',
    DAMAGE_PHOTOS: 'damage_photos',
    CONTAINER_CONDITION_PHOTOS: 'container_condition_photos'
  }
}

export default constants
