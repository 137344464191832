import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  margin: theme.spacing(3)
}))

function ListSkeleton({ length = 1, height = 50, width = '100%', ...props }) {
  const times = [...Array(length).keys()]

  return (
    <Grid container>
      {times.map(time => (
        <StyledSkeleton
          key={time}
          variant='rectangular'
          width={width}
          height={height}
          {...props}
        />
      ))}
    </Grid>
  )
}

ListSkeleton.propTypes = {
  length: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  props: PropTypes.object
}

export default ListSkeleton
