import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function Head({ title }) {
  return <Helmet titleTemplate={`%s | SG`} title={title} />
}

Head.propTypes = {
  title: PropTypes.string.isRequired
}

export default Head
