import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'

function BaseSnackbar({ isVisible, message, type }) {
  return (
    <Snackbar
      open={isVisible}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant='filled' severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

BaseSnackbar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error']).isRequired
}

export default BaseSnackbar
