import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { ipfsConfig } from 'config'

const StyledImg = styled('img')(() => ({
  maxWidth: 250,
  maxHeight: 175,
  height: 'auto',
  minHeight: 175,
  borderRadius: 3
}))

function FileDetail({ label, value, divider = true }) {
  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='row'
      justifyContent='space-around'
      alignItems='center'
      borderBottom={divider ? 1 : 0}
      borderBottomColor='divider'
    >
      <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
        <Typography variant='caption' gutterBottom>
          {label}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center' my={2} flex={1}>
        <StyledImg src={`${ipfsConfig.url}${value}`} alt={value} />
      </Box>
    </Box>
  )
}

FileDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  divider: PropTypes.bool
}

export default FileDetail
