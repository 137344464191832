import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useViewport } from 'hooks'

function FullPageLoading() {
  const { vh } = useViewport()
  const fullHeight = vh(100)
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      style={{ height: fullHeight }}
    >
      <Typography color='primary' variant='subtitle1'>
        {t('loading')}
      </Typography>
    </Box>
  )
}

export default FullPageLoading
