import { hapiConfig } from 'config'
import { authUtils } from 'utils'

const get = (endpoint, customHeaders = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      const request = await window.fetch(`${hapiConfig.url}${endpoint}`, {
        method: 'GET',
        headers: {
          authorization: authUtils.getAccessToken(),
          ...customHeaders
        }
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

const post = (endpoint, data, isFormData = false) =>
  new Promise(async (resolve, reject) => {
    try {
      const request = await window.fetch(`${hapiConfig.url}${endpoint}`, {
        method: 'POST',
        body: isFormData ? data : JSON.stringify(data),
        headers: isFormData
          ? {
              authorization: authUtils.getAccessToken()
            }
          : {
              'Content-Type': 'application/json',
              authorization: authUtils.getAccessToken()
            }
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

export default {
  get,
  post
}
