import React from 'react'
import PropTypes from 'prop-types'
import {
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import { LinkedCamera as LinkedCameraIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { BaseDialog } from 'components'

function CameraSelect({ cameras, onCameraSelect, open = false, handleClose }) {
  const { t } = useTranslation()

  return (
    <BaseDialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{t('selectCamera')}</DialogTitle>
      <List>
        {cameras &&
          cameras.map(camera => (
            <ListItem
              button
              onClick={() => onCameraSelect(camera)}
              key={camera.id}
            >
              <ListItemAvatar>
                <Avatar>
                  <LinkedCameraIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={camera.name} />
            </ListItem>
          ))}
      </List>
    </BaseDialog>
  )
}

CameraSelect.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.object),
  onCameraSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default CameraSelect
