import { useReducer } from 'react'

import { fetchUtils } from 'utils'

const usePost = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const post = async (url, data, callback) => {
    try {
      setState({
        loading: true,
        error: null
      })

      const response = await fetchUtils.post(url, data)

      setState({
        loading: false,
        data: response
      })

      if (callback && typeof callback === 'function') {
        callback(response)
      }
    } catch (error) {
      setState({
        loading: false,
        error
      })
    }
  }

  return {
    ...state,
    post
  }
}

export default usePost
