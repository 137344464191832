import React, { lazy, Suspense } from 'react'
import { ApolloProvider } from '@apollo/client'

import { FullPageLoading } from 'components'
import { generateApolloClient } from 'config'
import { useUser } from 'context/auth/user'

const Authenticated = lazy(() => import('./screens/Authenticated'))
const Unauthenticated = lazy(() => import('./screens/Unauthenticated'))

function App() {
  const { user } = useUser()

  return (
    <ApolloProvider client={generateApolloClient()}>
      <Suspense fallback={<FullPageLoading />}>
        {user ? <Authenticated /> : <Unauthenticated />}
      </Suspense>
    </ApolloProvider>
  )
}

export default App
