import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BaseButton } from 'components'

function QueryError({ error, ...props }) {
  const { t } = useTranslation()

  return (
    <>
      <Box my={3}>
        <Typography variant='body2' gutterBottom>
          {t('fatalError')}
        </Typography>
        {error?.graphQLErrors?.map(({ message }, i) => (
          <Typography key={i} variant='body2' gutterBottom>
            {message}
          </Typography>
        ))}
      </Box>

      <BaseButton {...props}>Retry</BaseButton>
    </>
  )
}

QueryError.propTypes = {
  error: PropTypes.object
}

export default QueryError
